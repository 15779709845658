import * as React from 'react';
import styled from 'styled-components';

import { LegendBox } from '@independent-software/mapbox-ext/controls/Legend/LegendBox';
import { ISkin } from '@independent-software/mapbox-ext/types/Skin';
import { PieChart } from '../pie/PieChart';
import { FlexTable } from '../FlexTable';
import { Number } from '../../formatters';
import { Config } from '../../../Config';
import { PieColors } from '../pie/PieColors';


interface IProps { 
  className?: string;
  title: string;
  agriculture: number;
  natural: number;
  livestock: number;
  waste: number;
  /** Appearance */
  skin: ISkin;
}

const WatershedBoxBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Title>{props.title}</Title>
      <PieChart values={[props.agriculture, props.natural, props.livestock, props.waste]}/>
      <FlexTable items={[
        { 
          name: <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}><LegendBox color={PieColors[0]}/> Agriculture</div>,
          value: <Number value={props.agriculture ?? 0} decimals={2}/>,
          unit: 'ton/year'
        }, {
          name: <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}><LegendBox color={PieColors[1]}/> Natural areas</div>,
          value: <Number value={props.natural ?? 0} decimals={2}/>,
          unit: 'ton/year'
        }, {
          name: <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}><LegendBox color={PieColors[2]}/> Livestock</div>,
          value: <Number value={props.livestock ?? 0} decimals={2}/>,
          unit: 'ton/year'
        }, {
          name: <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}><LegendBox color={PieColors[3]}/> Wastewater</div>,
          value: <Number value={props.waste ?? 0} decimals={2}/>,
          unit: 'ton/year'
        }
      ]}/>
    </div>
  );
}

const Title = styled.div`
  padding-left: 1px;
  padding-bottom: 4px;
  color: #32DFC0;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100%;  
  @media (max-width: ${Config.UI.large_screen}px) {
    display: none;
  }
`

const WatershedBox = styled(WatershedBoxBase)`
  @media (min-width: ${Config.UI.large_screen}px) {
    bottom: -2px;
    position: absolute;
    right: -220px;
    width: 210px;    
    border: solid ${p => p.skin.bordersize}px ${p => p.skin.border};
    border-radius: ${p => p.skin.radius}px;
    background: #000;    
    box-shadow: ${p => p.skin.boxshadow};
    padding: 5px;
  }
  @media (max-width: ${Config.UI.large_screen}px) {
  }
  box-sizing: border-box;
  
`

export { WatershedBox }
