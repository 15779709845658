import * as React from 'react';
import styled, { css } from 'styled-components';
import { IDataset } from '../../../types/IDataset';

interface IProps {
  /** @ignore */
  className?: string;
  /** Dataset to show in menu item. */
  data: IDataset;
  /** Fired when menu item is clicked. */
  onClick: () => void;
}

const MenuItemBase = (props: IProps) => {
  const handleClick = (e: React.MouseEvent) => {
    // Must stop propagation for menu not to close itself:
    e.stopPropagation();
    props.onClick();
  }

  return (
    <div className={props.className} onClick={handleClick}>
      <TextBlock>
        <Title>
          {props.data.title}
        </Title>
        <Description>
          {props.data.description ?? props.data.title}
        </Description>
      </TextBlock>
      <Handle>
        <Eye>
          <svg><use xlinkHref={"sprites.svg#eye"}></use></svg>
        </Eye>
      </Handle>
    </div>
  )
}

const Title = styled.div`
  color: #fff;
  line-height: 1em;
`

const Description = styled.div`
  color: #32DFC0;
  margin-top: 2px;
  font-size: 12px;
  line-height: 1.2em;
`

const Eye = styled.div`
  position: absolute;
  top: 12px;
  right: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  svg {
    width: 16px;
    height: 16px;
    fill: #32DFC0;
  }
`

const TextBlock = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: calc(100% - 39px);
  padding: 16px 16px 0 16px;
  box-sizing: border-box;
  background: rgba(0,0,0,0.4);
  transition: background-color ease-in-out 100ms;
`

const Handle = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 36px;
  background: rgba(0,0,0,0.4);
  transition: background-color ease-in-out 100ms;
`

const MenuItem = styled(MenuItemBase)`
  position: relative;
  color: #fff;
  height: 68px;
  box-sizing: border-box;
  margin-bottom: 3px;
  cursor: pointer;
  text-align: right;
  &:hover {
    ${TextBlock} {
      background-color: rgba(0,0,0,0.9);
    }
    ${Handle} {
      background-color: rgba(0,0,0,0.9);
    }
  }
  ${Eye} {
    ${p => p.data.active && css`
      display: block;
    `}
  }
`

export { MenuItem }
