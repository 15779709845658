import * as React from 'react';
import { IConfig } from './types/Config.Type';
import { ILoaderProps } from './map/layer/ILoaderProps';
import { FerryRoutes } from './map/layers/rwanda/lines/FerryRoutes';
import { WaterQuality } from './map/layers/rwanda/suitability/WaterQuality';
import { Industries } from './map/layers/rwanda/points/Industries';
import { Tourism } from './map/layers/rwanda/points/Tourism';
import { WaterSupply } from './map/layers/rwanda/points/WaterSupply';
import { Hatcheries } from './map/layers/rwanda/points/Hatcheries';
import { RiverMouths } from './map/layers/rwanda/points/RiverMouths';
import { SamplingStations } from './map/layers/rwanda/points/SamplingStations';
import { AquacultureSites } from './map/layers/rwanda/points/AquacultureSites';
import { UrbanAreas } from './map/layers/rwanda/points/UrbanAreas';
import { Airports } from './map/layers/rwanda/points/Airports';
import { ProcessingPlants } from './map/layers/rwanda/points/ProcessingPlants';
import { AdminProvinces } from './map/layers/rwanda/admin/AdminProvices';
import { AdminDistricts } from './map/layers/rwanda/admin/AdminDistricts';
import { AdminSectors } from './map/layers/rwanda/admin/AdminSectors';
import { Rivers } from './map/layers/rwanda/lines/Rivers';
import { Roads } from './map/layers/rwanda/lines/Roads';
import { Anchorages } from './map/layers/rwanda/points/Anchorages';
import { LandingSites } from './map/layers/rwanda/points/LandingSites';
import { InshoreSiteSelection } from './map/layers/rwanda/suitability/InshoreSiteSelection';
import { OffshoreSiteSelection } from './map/layers/rwanda/suitability/OffshoreSiteSelection';
import { InshoreSocioeconomic } from './map/layers/rwanda/suitability/InshoreSocioeconomic';
import { OffshoreSocioeconomic } from './map/layers/rwanda/suitability/OffshoreSocioeconomic';
import { Extraction } from './map/layers/rwanda/suitability/Extraction';
import { AquaCultureDevelopmentZones } from './map/layers/rwanda/suitability/AquaCultureDevelopmentZones';
import { NavigationRoutes } from './map/layers/rwanda/lines/NavigationRoutes';

const Config: IConfig = {
  map: {
    mapKey: "pk.eyJ1IjoibG9uZ2xpbmVlbnZpcm9ubWVudCIsImEiOiJjbGF0cHF1ZWUwM2l0M3FwcDcyN3B1YXpmIn0.snFi9yTPEZ5lfQxE3h3Epg",
    greyStyle: null, // "mapbox://styles/longlineenvironment/clatpsjsl003r15okdwsdclmi",
    satelliteStyle: "mapbox://styles/longlineenvironment/clnkins3a001s01qw9k3efaj5/draft",
    longitude: 29, 
    latitude: -2,  
    zoom: 8, 
    minZoom: 8,
    maxZoom: 14,
    bearing: 0,
    pitch: 0,
    max_bounds: [
      [26.7, -3.8],    // Southwest (lng/lat)
      [32.3,  0.0]     // Northeast (lng/lat)
    ],
    password: "amaziamazi"
  },
  UI: {
    height: 75,
    before_layer: 'waterway-label',
    large_screen: 900,
    load_delay_base: 0,
    load_delay_random: 0,
  },

  suitability: [
    { name: 'Spatial constraints', color: '#aaaaaa' },
    { name: 'Not suitable', color: '#dc1010' },
    { name: 'Moderately suitable', color: '#ff6d01' },
    { name: 'Suitable', color: '#128800' },
    { name: 'Highly suitable', color: '#0cf600' },
  ],  

  datasets: [{
      key: 'aquaculture_development_zones',
      ids: [ 'aquaculture_development_zones_offshore', 'aquaculture_development_zones_inshore', 'aquaculture_development_zones_constraints' ],
      title: 'Aquaculture development zones',
      description: 'Designated areas for fostering aquaculture development',
      active: false,
      group: 'aquaculture',
      renderer: (p: ILoaderProps) => <AquaCultureDevelopmentZones {...p}/>
    }, {
      key: 'inshore_site_selection',
      ids: [ 'inshore_site_selection' ],
      title: 'Inshore site selection',
      description: 'Suitability areas for Nile Tilapia cage inshore aquaculture',
      active: true,
      group: 'aquaculture',
      renderer: (p: ILoaderProps) => <InshoreSiteSelection {...p}/>
    }, {
      key: 'offshore_site_selection',
      ids: [ 'offshore_site_selection' ],
      title: 'Offshore site selection',
      description: 'Suitability areas for Nile Tilapia cage offshore aquaculture',
      active: false,
      group: 'aquaculture',
      renderer: (p: ILoaderProps) => <OffshoreSiteSelection {...p}/>
    }, {
      key: 'inshore_socioeconomic',
      ids: [ 'inshore_socioeconomic' ],
      title: 'Inshore socioeconomic suitability',
      description: 'Socioeconomic suitability for Nile Tilapia inshore aquaculture',
      active: false,
      group: 'aquaculture',
      renderer: (p: ILoaderProps) => <InshoreSocioeconomic {...p}/>
    }, {
      key: 'offshore_socioeconomic',
      ids: [ 'offshore_socioeconomic' ],
      title: 'Offshore socioeconomic suitability',
      description: 'Socioeconomic suitability for Nile Tilapia offshore aquaculture',
      active: false,
      group: 'aquaculture',
      renderer: (p: ILoaderProps) => <OffshoreSocioeconomic {...p}/>
    }, {
      key: 'water_quality',
      ids: [ 'water_quality' ],
      title: 'Water quality suitability',
      description: 'Water quality suitability for Nile Tilapia farming',
      active: false,
      group: 'aquaculture',
      renderer: (p: ILoaderProps) => <WaterQuality {...p}/>
    }, {
      key: 'extraction',
      ids: [ 'extraction' ],
      title: 'Methane extraction',
      description: 'Methane extraction and production industry',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <Extraction {...p}/>
    }, {
      key: 'anchorages',
      ids: [ 'anchorages' ],
      title: 'Anchorages',
      description: 'Ferry boat landing sites',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <Anchorages {...p}/>
    }, {
      key: 'landing_sites',
      ids: [ 'landing_sites' ],
      title: 'Fish landing sites',
      description: 'Fish landing sites and piers',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <LandingSites {...p}/>
    }, {
      key: 'ferry_routes',
      ids: [ 'ferry_routes' ],
      title: 'Ferry routes',
      description: 'WaterBus Lake Kivu Rwanda ferry routes',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <FerryRoutes {...p}/>
    }, {
      key: 'navigation_routes',
      ids: [ 'navigation_routes' ],
      title: 'Navigation routes',
      description: 'Proposed navigation routes in Water Use Master Plan',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <NavigationRoutes {...p}/>
    }, {
      key: 'industries',
      ids: [ 'industries' ],
      title: 'Industries',
      description: 'Lake Kivu industries',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <Industries {...p}/>
    }, {
      key: 'tourism',
      ids: [ 'tourism' ],
      title: 'Touristic settlements',
      description: 'Lake Kivu touristic settlements',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <Tourism {...p}/>
    }, {
      key: 'water_supply',
      ids: [ 'water_supply' ],
      title: 'Water intake stations',
      description: 'Domestic water supply (Nyarutovu cell)',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <WaterSupply {...p}/>
    }, {
      key: 'hatcheries',
      ids: [ 'hatcheries' ],
      title: 'Hatcheries',
      description: 'Fish hatcheries (Nyarutovu cell)',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <Hatcheries {...p}/>
    }, {
      key: 'river_mouths',
      ids: [ 'river_mouths' ],
      title: 'River mouths',
      description: 'Rwanda West Province river mouths',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <RiverMouths {...p}/>
    }, {
      key: 'sampling_stations',
      ids: [ 'sampling_stations' ],
      title: 'Sampling stations',
      description: 'Water quality sampling stations',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <SamplingStations {...p}/>
    }, {
      key: 'aquaculture_sites',
      ids: [ 'aquaculture_sites' ],
      title: 'Aquaculture sites',
      description: 'Lake Kivu aquaculture sites',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <AquacultureSites {...p}/>
    }, {
      key: 'urban_areas',
      ids: [ 'urban_areas' ],
      title: 'Urban areas',
      description: 'West Province urban settlements',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <UrbanAreas {...p}/>
    }, {
      key: 'airports',
      ids: [ 'airports' ],
      title: 'Airports',
      description: 'West Province airports',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <Airports {...p}/>
    }, {
      key: 'processing_plants',
      ids: [ 'processing_plants' ],
      title: 'Fish processing plants',
      description: 'Fishing and processing of Isambaza (Smart fish)',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <ProcessingPlants {...p}/>
    }, {
      key: 'admin_provinces',
      ids: [ 'admin_provinces' ],
      title: 'Provinces',
      description: 'Rwanda provinces',
      group: 'administrative_boundaries',
      renderer: (p: ILoaderProps) => <AdminProvinces {...p}/>
    }, {
      key: 'admin_districts',
      ids: [ 'admin_districts' ],
      title: 'Districts',
      description: 'Rwanda West Province districts',
      group: 'administrative_boundaries',
      renderer: (p: ILoaderProps) => <AdminDistricts {...p}/>
    }, {
      key: 'admin_sectors',
      ids: [ 'admin_sectors' ],
      title: 'Sectors',
      description: 'Rwanda West Province sectors',
      group: 'administrative_boundaries',
      renderer: (p: ILoaderProps) => <AdminSectors {...p}/>
    }, {
      key: 'rivers',
      ids: [ 'rivers' ],
      title: 'Rivers',
      description: 'Rwanda West Province rivers',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <Rivers {...p}/>
    }, {
      key: 'roads',
      ids: [ 'roads' ],
      title: 'Roads',
      description: 'West Province road network',
      group: 'spatial_planning',
      renderer: (p: ILoaderProps) => <Roads {...p}/>
    }
  ],
  datagroups: [
    {
      key: 'aquaculture',
      title: 'Cage aquaculture',
      description: 'Suitability for Nile tilapia cage aquaculture',
      exclusive: true,
    }, { 
      key: 'spatial_planning',
      title: 'Spatial planning features',
      description: 'Aquaculture spatial plan base datasets',
      exclusive: false
    }, { 
      key: 'administrative_boundaries',
      title: 'Administrative boundaries',
      description: '',
      exclusive: false
    }
  ],
  dictionary: [
    {
      key: 'suitability',
      text: 'Suitability',
      type: 'suitability'
    }, {
      key: 'zone_name',
      text: 'Zone name'
    }, {
      key: 'area_km2',
      text: 'Area',
      unit: <>km&sup2;</>,
      type: 'number'
    }, {
      key: 'ADM1_EN',
      text: 'Province'
    }, {
      key: 'ADM2_EN',
      text: 'District'
    }, {
      key: 'ADM3_EN',
      text: 'Sector'
    }, {
      key: 'establishment_type',
      text: 'Type'
    }, {
      key: 'type',
      text: 'Type'
    }, {
      key: 'name',
      text: 'Name'
    }, {
      key: 'description',
      text: 'Description'
    },
    { key: 'SOURCE', text: 'Source' },
    { key: 'ORIGINAL ID', text: 'Original ID' },
    { key: 'SITE', text: 'Site' },
    { key: 'COUNTY', text: 'County' },
    { key: 'COUNTY CODE', text: 'County code' },
    { key: 'NAME', text: 'Name' },
    { key: 'CODE', text: 'Code' },
    { key: 'Temp_C', text: 'Temperature', unit: 'C', type: 'number' },
    { key: 'DO_mgL-1', text: 'Dissolved oxygen', unit: 'mg L-1', type: 'number' },
    { key: 'pH', text: 'pH', type: 'number' },
    { key: 'TDS_mgL-1', text: 'Dissolve solids', unit: 'mg L-1', type: 'number' },
  ]
}

export { Config }
