import * as React from 'react';

import { GuideBox } from '../elements/GuideBox';
import { GuideButton } from '../elements/GuideButton';
import { GuideIcon } from '../elements/GuideIcon';

interface IProps {
  onCancel: () => void;
  onStage: (s: string) => void;
}

const FullscreenGuide = (props: IProps) => {
  return (
    <GuideBox top="32px" right="60px" onCancel={props.onCancel}>
      <h1>Fullscreen display</h1>
      <p><em>The map size can be increased to fill your browser window.</em></p>
      <p>Click the <GuideIcon icon="fullscreen"/> icon to toggle fullscreen display mode.</p>
      <p>Press ESC to disable fullscreen mode.</p>
      <GuideButton onClick={() => props.onStage(null)}>Finish</GuideButton>
    </GuideBox>
  );
}

export { FullscreenGuide }
