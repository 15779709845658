import * as React from 'react';
import styled from 'styled-components';

import { PieColors } from './PieColors';
import { PieSlice } from './PieSlice';
import { Config } from '../../../Config';

interface IProps { 
  className?: string;
  values: number[];
}

const PieChartBase = (props: IProps) => {
  // Calculate percentage slices from values:
  const total = props.values.reduce((acc, cur) => acc + (cur ?? 0), 0);
  let prev = 0;
  const slices = props.values.map(v => {
    if(!v) v = 0;
    const s = [prev, prev + v / total * 100];
    prev += v / total * 100;
    return s;
  });

  return (
    <div className={props.className}>
      {slices.map((s, idx) => <PieSlice key={idx} start={s[0]} end={s[1]} color={PieColors[idx]}/>)}
    </div>
  )
}

const PieChart = styled(PieChartBase)`
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 50%;
  transform: translateX(-50%);
  @media (min-width: ${Config.UI.large_screen}px) {
    width: 120px;
    height: 120px;
  }
  @media (max-width: ${Config.UI.large_screen}px) {
    width: 80px;
    height: 80px;
  }
`

export { PieChart }
