import * as React from 'react';
import styled, { css } from 'styled-components';

import { IDataset } from '../../../types/IDataset';
import { IDatagroup } from '../../../types/IDatagroup';
import { MenuGroup } from './MenuGroup';
import { Config } from '../../../Config';

interface IProps {
  /** @ignore */
  className?: string;
  /** Is the menu currently open? */
  open: boolean;
  /** Datasets to show in menu. */
  datasets: IDataset[];
  /** Datagroups to show in menu. */
  datagroups: IDatagroup[];
  /** Fired when a menu item is clicked. */
  onToggleLayer: (key: string) => void;
  /** Fired when menu is toggled. */
  onToggle: () => void;
}

const MenuBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <MenuButton onClick={props.onToggle}>
        <svg><use xlinkHref={"sprites.svg#caret"}></use></svg> 
      </MenuButton>
      {/* Show all datagroups. */}
      <Scroller>
        {props.datagroups.map((group, idx) => 
          <MenuGroup key={idx} group={group} datasets={props.datasets.filter(d => d.group == group.key)} onClick={props.onToggleLayer}/>)}
      </Scroller>
    </div>
  )
}

const MenuButton = styled.div`
  position: absolute;
  z-index: 1;
  right: 100%;
  top: 0;
  bottom: 0;
  width: 30px;
  cursor: pointer;
  user-select: none;
  background: rgba(255,255,255,0.5);
  transition: border-color ease-in-out 0.1s,
              background-color ease-in-out 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 25px;
    height: 25px;
    fill: #333;
    transition: fill ease-in-out 0.1s, transform ease-in-out 0.1s;
  }
  &:hover {
    background: rgba(255,255,255,0.6);
  }  
`

const Scroller = styled.div`
  position: absolute;
  left: 12px;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(3px);
  padding: 0 0 0 0;
`

const Menu = styled(MenuBase)`
  position: absolute;
  // Small screen:
  right: ${p => p.open ? 0 : 'calc(30px - 100%)'};
  width: calc(100% - 30px);
  // Large screen
  @media (min-width: ${Config.UI.large_screen}px) {
    right: ${p => p.open ? 0 : '-400px'};
    width: 400px;
  }
  top: 0;
  bottom: 0;
  z-index: 1000;
  
  user-select: none;
  box-sizing: border-box;
  transition: right ease-in-out 150ms;
  
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
    width: 10px;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(3px);
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 5px;
    background: rgba(0,0,0,0.8);
    backdrop-filter: blur(3px);
  }

  // Menu button rotates when the menu opens.
  ${MenuButton} {
    svg {
      transform: rotate(${p => p.open ? 0 : 180}deg);
    }
  }

  // MenuButton isn't present when menu is open AND on a large screen.
  ${MenuButton} {
    ${p => p.open && css`
      @media (min-width: ${Config.UI.large_screen}px) {
        display: none;
    `}
  }
`

export { Menu }