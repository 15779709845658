import * as React from 'react';
import styled from 'styled-components';
import { DefaultSkin, ISkin } from '@independent-software/mapbox-ext/types/Skin';
import { MapboxGeoJSONFeature } from 'mapbox-gl';

import { IDataset } from '../../../types/IDataset';
import { WatershedBox } from './WatershedBox';
import { FlexTable } from '../FlexTable';
import { TranslatedName, TranslatedValue } from '../../formatters';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Appearance */
  skin: ISkin;
  /** Dataset to show */
  dataset: IDataset;
  /** Feature with properties */
  feature: MapboxGeoJSONFeature;
  /** Fired when dataset must be deactivated. */
  onDeactivate: () => void;
}

/**
 * List of property keys that must always be skipped. These are either used
 * for pie charts, or internal to the system (like cluster IDs) and mustn't 
 * be shown to users.
 */
const SkipList = [
  'TN',
  'TN_agriculture',
  'TN_natural',
  'TN_livestock',
  'TN_waste',
  'TP',
  'TP_agriculture',
  'TP_natural',
  'TP_livestock',
  'TP_waste',
  'point_count',
  'cluster',
  'cluster_id',
  'point_count_abbreviated'
]

const InfoBoxBase = (props: IProps) => {
  const getNitrogenPiechart = (feature: MapboxGeoJSONFeature): React.ReactNode => {
    return <WatershedBox 
      skin={props.skin}
      title="Total nitrogen"
      agriculture={feature.properties["TN_agriculture"]}
      livestock={feature.properties["TN_livestock"]}
      natural={feature.properties["TN_natural"]}
      waste={feature.properties["TN_waste"]}
    />
  }

  const getPhosphorusPiechart = (feature: MapboxGeoJSONFeature): React.ReactNode => {
    return <WatershedBox 
      skin={props.skin}
      title="Total phosphorus"
      agriculture={feature.properties["TP_agriculture"]}
      livestock={feature.properties["TP_livestock"]}
      natural={feature.properties["TP_natural"]}
      waste={feature.properties["TP_waste"]}
    />
  }

  const getLayerTable = (feature: MapboxGeoJSONFeature): React.ReactNode => {
    // Get all properties from Feature, and produce table rows:
    const items = Object.keys(feature.properties)
      // Filter out properties that must never be shown:
      .filter(k => !SkipList.includes(k))
      .map(k => {
        return {
          name: <TranslatedName name={k}/>,
          value: <TranslatedValue name={k} value={feature.properties[k]}/>,
          unit: null
        }
      });

    return <FlexTable items={items}/>
  }



  return (
    <div className={props.className}>
      <Title>
        {props.dataset.title}
      </Title>
      <CloserWrapper>
        <Closer onClick={props.onDeactivate}>
          <svg><use xlinkHref={"sprites.svg#times"}></use></svg>
        </Closer>
      </CloserWrapper>
      {props.feature && <>
        {getLayerTable(props.feature)}
        {(props.dataset.key == 'county_nitrogen' || props.dataset.key == 'watershed_nitrogen') && getNitrogenPiechart(props.feature)}
        {(props.dataset.key == 'county_phosphorus' || props.dataset.key == 'watershed_phosphorus') && getPhosphorusPiechart(props.feature)}
      </>}
    </div>
  )
}

const Title = styled.div`
  color: #32DFC0;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100%;
`

const CloserWrapper = styled.div`
  position: absolute;
  left: 100%;
  top: 0;
  padding-left: 8px;
  background: transparent;
  opacity: 0;
`

const Closer = styled.div`
  width: 25px;
  height: 25px;
  background: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color ease-in-out 100ms;
  &:hover {
    background: #333;
  }
  svg {
    width: 75%;
    height: 75%;
    fill: #f0f0f0;
    transition: fill ease-in-out 100ms;
    &:hover {
      fill: #f0f0f0;
    }
  }
`

const InfoBox = styled(InfoBoxBase).attrs(p => ({
  skin: p.skin ?? DefaultSkin
}))`
  position: relative;
  background: ${p => p.feature ? '#000' : p.skin.fill};
  width: 300px;
  box-shadow: ${p => p.skin.boxshadow};
  border: solid ${p => p.skin.bordersize}px ${p => p.skin.border};
  border-radius: ${p => p.skin.radius}px;
  box-sizing: border-box;
  padding: 5px;
  user-select: none;
  &:hover {
    ${CloserWrapper} {
      opacity: 1;
    }
  }
`

export { InfoBox }


