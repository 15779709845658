import * as React from 'react';
import styled from 'styled-components';

import { MapHolder } from './MapHolder';
import { Menu } from './components/menu/Menu';
import { Config } from '../Config';

interface IProps {
  /** @ignore */
  className?: string;
}

const MainBase = (props: IProps) => {
  const containerRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [datasets, setDatasets] = React.useState(Config.datasets);

  // Listen for document-wide ESC keydown. This will close the nmenu.
  React.useEffect(() => {
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      if(e.key === 'Escape') setOpen(false); 
    });
  }, []);

  // When a HTML element is assigned to the fullscreen container ref, we must
  // let the child components know about it. This done by forcing a rerender.
  // The rerender is forced by updating the state.
  React.useEffect(() => { 
    setDatasets([...datasets]);
  }, [containerRef]);

  const handleToggle = () => setOpen(!open);

  const handleClose = () => {
    if(open === true) setOpen(false);
  }

  const handleToggleLayer = (datasetKey: string) => {
    // Find the dataset identified by the key.
    const dataset = datasets.find(d => d.key === datasetKey);
    if(!dataset) {
      console.error(`Dataset ${datasetKey} not found.`);
      return;
    }
    // Deactivate all other datasets in the same group, if the group is
    // an exclusive group.
    if(dataset.group) {
      const group = Config.datagroups.find(g => g.key === dataset.group);
      if(!group) {
        console.error(`Datagroup ${dataset.group} not found.`);
        return;
      }
      if(group.exclusive) {
        datasets
          .filter(d => d.group === group.key && d.key != datasetKey)
          .forEach(d => d.active = false);
      }
    }
    dataset.active = !dataset.active;
    setDatasets([...datasets]);
  }

  return (
    <div className={props.className} onClick={handleClose} ref={containerRef}>
      <Menu datasets={datasets} datagroups={Config.datagroups} open={open} onToggleLayer={handleToggleLayer} onToggle={handleToggle}/>
      <MapHolder fullscreenContainer={containerRef.current} datasets={datasets} open={open} onDeactivate={handleToggleLayer}/>
    </div>
  );
}

const Main = styled(MainBase)`
  width: 100%;
  height: 100%;
  background: conic-gradient(from 0deg at 0 0, #071119 0deg, #071119 90deg, #243961 180deg, #243961 -1deg);
  overflow: hidden;
`

export { Main }
