import * as React from 'react';

import { GuideBox } from '../elements/GuideBox';
import { GuideLink } from '../elements/GuideLink';
import { Config } from '../../../Config';

interface IProps {
  onCancel: () => void;
  onStage: (s: string) => void;
}

const WelcomeGuide = (props: IProps) => {
  return (
    <GuideBox top="32px" right="60px" onCancel={props.onCancel}>
      <h1>Hello!</h1>
      <p><em>Let us help you get started with this map.</em></p>
      <GuideLink onClick={() => props.onStage('navigation')}>Navigation</GuideLink>
      {/* Show style guide only if a secondary style is available: */}
      {Config.map.greyStyle && <GuideLink onClick={() => props.onStage('satellite')}>Satellite imagery</GuideLink>}
      <GuideLink onClick={() => props.onStage('fullscreen')}>Fullscreen display</GuideLink>
      <GuideLink onClick={() => props.onStage('layers')}>Map layers</GuideLink>
    </GuideBox>
  );
}

export { WelcomeGuide }
