import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../../layer/ILoaderProps';
import { LoadableSource } from '../../../layer/LoadableSource';
import { Config } from '../../../../Config';

const Roads = (props: ILoaderProps) => {
  return (
    <LoadableSource path="https://apigeostore.longline.co.uk/dataset/6bcf582113519d0e31833f4af171964d9f40f1bb" {...props}>
      <Layer
        id='roads'
        beforeId={Config.UI.before_layer}
        type="line"
        layout={{ visibility: props.active ? "visible" : "none" }}          
        paint={{ 
          "line-color": "white",
          "line-width": [
            'interpolate',
            ['linear'],
            ["zoom"],
            0, 3,
            8, 5,
            18, 13
          ],
        }}        
      />
      <Layer
        id='roads_stroke'
        beforeId={Config.UI.before_layer}
        type="line"
        layout={{ visibility: props.active ? "visible" : "none" }}          
        paint={{ 
          "line-color": "#555",
          "line-width": [
            'interpolate',
            ['linear'],
            ["zoom"],
            0, 1,
            8, 2,
            18, 10
          ],
        }}
      />

    </LoadableSource>
  );
}

export { Roads }
