import { ISkin } from "@independent-software/mapbox-ext/types/Skin";

const SawaSkin: ISkin = {
  fill: "#333",
  border: "#cccccc",
  bordersize: 2,
  disabled: "#888",
  hover: "#fff",
  active: "rgb(255,215,0,0.8)",
  radius: 0,
  boxshadow: "none"
}

export { SawaSkin }
