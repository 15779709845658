import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../../layer/ILoaderProps';
import { LoadableSource } from '../../../layer/LoadableSource';
import { Config } from '../../../../Config';

const Rivers = (props: ILoaderProps) => {
  return (
    <LoadableSource path="https://apigeostore.longline.co.uk/dataset/5ddb657d70cba7f9ea5d8c6ba51ef6b1c70f4315" {...props}>
      <Layer
        id='rivers'
        beforeId={Config.UI.before_layer}
        type="line"
        layout={{ visibility: props.active ? "visible" : "none" }}          
        paint={{ 
          "line-color": "white",
          "line-width": [
            'interpolate',
            ['linear'],
            ["zoom"],
            0, 3,
            8, 5,
            18, 13
          ],
        }}        
      />
      <Layer
        id='rivers_stroke'
        beforeId={Config.UI.before_layer}
        type="line"
        layout={{ visibility: props.active ? "visible" : "none" }}          
        paint={{ 
          "line-color": "steelblue",
          "line-width": [
            'interpolate',
            ['linear'],
            ["zoom"],
            0, 1,
            8, 2,
            18, 10
          ],
        }}
      />

    </LoadableSource>
  );
}

export { Rivers }
