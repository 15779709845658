import * as React from 'react';
import styled from 'styled-components';

interface IProps { 
  /** @ignore */
  className?: string;
  /** Is this the active index? */
  active?: boolean;
  /** Fired when button is clicked. */
  onClick?: () => void;
}

const SlideIndexButtonBase = (props: IProps) => 
  <div className={props.className} onClick={props.onClick} title="Jump to slide">
    <Inner/>
  </div>

// Inner block, with a border to inset it into the outer button.
const Inner = styled.div`
  width: 10px;
  height: 10px;
  border: solid 1px black;
  transition: background-color ease-in-out 80ms;
`

const SlideIndexButton = styled(SlideIndexButtonBase)`
  border: solid 1px #aaa;
  margin: 3px;
  cursor: pointer;
  margin: 3px;  

  ${Inner} {
    background: ${p => p.active ? '#aaa' : 'transparent'};
  }
  &:hover {
    ${Inner} {
      background: ${p => p.active ? '#aaa' : '#fff'};
    }
  }
`

export { SlideIndexButton }
