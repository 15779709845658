import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../../layer/ILoaderProps';
import { LoadableSource } from '../../../layer/LoadableSource';
import { Config } from '../../../../Config';

const AdminProvinces = (props: ILoaderProps) => 
  <LoadableSource path="https://apigeostore.longline.co.uk/dataset/ce0d114507923ab2d3bc069b36d66f160032d7ca" ignore={['ADM0_EN']} {...props}>
    <Layer
      id='admin_provinces'
      beforeId={Config.UI.before_layer}
      type='fill'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{ 
        "fill-color": "white",
        "fill-opacity": [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.2,
          0.1
          ]
      }}
    />
    <Layer
      id='admin_provinces_stroke'
      beforeId={Config.UI.before_layer}
      type='line'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{ 
        "line-color": "white",
        "line-width": 2,
        "line-dasharray": [ 5, 2, 2, 2 ]
      }}
    />
  </LoadableSource>

export { AdminProvinces } 
