import * as React from 'react';
import styled, { css } from 'styled-components';
import { DefaultSkin, ISkin } from '@independent-software/mapbox-ext/types/Skin';
import { Number } from '../formatters/Number';
import { Longitude } from '../formatters/GIS';
import { Latitude } from '../formatters/GIS';

interface IProps {
  /** @ignore */
  className?: string;
  /** Horizontal button position. A negative value is an offset from the right. */
  x: number;
  /** Vertical button position. A negative value is an offset from the bottom. */
  y: number;  
  /** Current mouse longitude */
  longitude: number;
  /** Current mouse latitude */
  latitude: number;
  /** Optional skin to apply. */
  skin?: ISkin;  
}

const DebugWindowBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <table>
        <tbody>
          <tr>
            <td>Longitude</td>
            <td>{props.longitude ? <Number value={props.longitude} decimals={3}/> : '-'}</td>
          </tr>
          <tr>
            <td colSpan={2}>{props.longitude ? <Longitude value={props.longitude}/> : '-'}</td>
          </tr>
          <tr>
            <td>Latitude</td>
            <td>{props.latitude ? <Number value={props.latitude} decimals={3}/> : '-'}</td>
          </tr>
          <tr>
            <td colSpan={2}>{props.latitude ? <Latitude value={props.latitude} /> : '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const DebugWindow = styled(DebugWindowBase).attrs(p => ({
  skin: p.skin ?? DefaultSkin
}))`
  position: absolute;
  color: #fff;
  ${p => p.x >= 0 && css`left:   ${ p.x}px;`}
  ${p => p.x < 0  && css`right:  ${-p.x}px;`}
  ${p => p.y >= 0 && css`top:    ${ p.y}px;`}
  ${p => p.y < 0  && css`bottom: ${-p.y}px;`}  
  z-index: 100;
  width: 150px;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px rgb(0,0,0,0.5);
  border: solid ${p => p.skin.bordersize}px ${p => p.skin.border};
  border-radius: ${p => p.skin.radius}px;
  background: ${p => p.skin.fill};
  padding: 6px 12px 6px 12px;
  user-select: none;
  pointer-events: none;
  color: ${p => p.skin.border};
  font-size: 12px;
  td {
    font-size: 12px;
    color: ${p => p.skin.border};
  }

  table {
    width: 100%;
  }
  td:first-child {
    width: 50px;
  }
  td:last-child {
    text-align: right;
  }
`

export { DebugWindow }
