import * as React from 'react';
import styled from 'styled-components';

interface IProps { 
  /** @ignore */
  className?: string;
  /** Slideshow currently running? */
  active: boolean;
  /** Fired when button is clicked. */
  onClick: () => void;
}

const SlidePauseButtonBase = (props: IProps) => {
  return (
    <div className={props.className} title={props.active ? 'Pause slideshow' : 'Run slideshow'} onClick={props.onClick}>
      <svg><use xlinkHref={props.active ? 'sprites.svg#pause' : 'sprites.svg#caret'}></use></svg>
    </div>
  )
}

/** 
 * Button to pause or unpause the slideshow. 
 */
const SlidePauseButton = styled(SlidePauseButtonBase)`
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
    fill: #ccc;
  }
  &:hover {
    svg { fill: #fff; }
  }
`

export { SlidePauseButton }
