import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Main } from './Main';

const Map = () => 
  <>
    <Main/>
  </>

const node = document.getElementById('mapview') as Element;
if(node) {
  const root = createRoot(node);
  root.render(<Map/>);
}

// Whenever webpack rebuilds the project, refresh the browser.
declare let module: any;
if (module.hot) {
  module.hot.accept(); 
}


