import * as React from 'react';

import { LegendBox } from '@independent-software/mapbox-ext/controls/Legend/LegendBox';

import { Number } from '../formatters/Number';
import { Config } from '../../Config';

interface IProps {
  name: string;
  value: any;
}

const TranslatedValue = (props: IProps) => {

  const getSuitability = (value: number): string => {
    if(value < 0 || value >= Config.suitability.length) return "Unknown";
    return Config.suitability[value].name;
  }

  const getSuitabilityColor = (value: number): string => {
    if(value < 0 || value >= Config.suitability.length) return "Unknown";
    return Config.suitability[value].color;
  }

  const attribute = Config.dictionary.find(x => x.key == props.name);

  if(!attribute) return props.value;

  switch(attribute.type) {
    case 'suitability':
      return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}><LegendBox color={getSuitabilityColor(props.value)}/> {getSuitability(props.value)}</div>;
    case 'number':
      return <Number value={props.value * (attribute.factor ? attribute.factor : 1)} scientific={attribute.scientific} decimals={attribute.decimals ?? 2}/>;
    default:
      return props.value;
  }    
}

export { TranslatedValue }
