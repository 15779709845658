import * as React from 'react';
import styled, { css } from 'styled-components';

import { ISlide } from './ISlide';

type ISlateState = 'ready' | 'onstage' | 'offstage';

interface IProps {
  /** @ignore */
  className?: string;
  /** Slide state: ready, on stage or off stage. */
  state: ISlateState;
  /** Slide settings */
  data: ISlide;
  /** Transition time (ms) */
  transitionTime: number;
  count: number;
  index: number;
}

const SlideBase = (props: IProps) => 
  <div className={props.className}>
    <Content>
      <Title>
        {props.data.title}
      </Title>
      <Text>
        {props.data.text}
      </Text>
    </Content>
  </div>

const Content = styled.div`
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
`

const Title = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 2vw;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 40px;
  text-transform: uppercase;
  max-width: 100%;
  white-space: nowrap;
  background: black;
  padding: 8px 15px 8px 15px;
  color: #fff;
  opacity: 0.7;
`

const Text = styled.div`
  display: block;
  background: black;
  padding: 8px 15px 8px 15px;
  color: #fff;
  opacity: 0.7;
  max-width: 60%;
  margin-top: 8px;
  max-height: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Slide = styled(SlideBase)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  ${p => p.state == 'ready' && css`
    left: 100vw;
    z-index: 0;
  `}
  ${p => p.state == 'onstage' && css`
    left: 0;
    z-index: 2;
  `}
  ${p => p.state == 'offstage' && css`
    left: 0;
    z-index: 1;
  `}

  transition: left ease-in-out ${p => p.transitionTime}ms;
  background: #ccc;
  background-image: url('assets/img/${p => p.data.image}');
  background-size: cover;
`

export { Slide }
