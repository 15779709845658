import * as React from 'react';
import styled from 'styled-components';

import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { InfoBox } from './InfoBox';
import { ISkin } from '@independent-software/mapbox-ext/types/Skin';
import { IDataset } from '../../../types/IDataset';
import { Config } from '../../../Config';

interface IProps {
  /** @ignore */
  className?: string;
  /** Features hovered */
  features: MapboxGeoJSONFeature[];
  /** Appearance */
  skin: ISkin;
  /** Datasets to show */
  datasets: IDataset[];
  /** Fired when a dataset must be deactivated. */
  onDeactivate: (datasetKey: string) => void;
}

const InfoBoxesBase = (props: IProps) => {

  return (
    <div className={props.className}>
      {props.datasets.filter(d => !d.hidden).map(dataset => 
        <InfoBox 
          key={dataset.key} 
          skin={props.skin} 
          dataset={dataset} 
          feature={props.features.find(f => dataset.ids.includes(f.layer.id))}
          onDeactivate={() => props.onDeactivate(dataset.key)}
        />
      )}
    </div>
  )
}

const InfoBoxes = styled(InfoBoxesBase)`
  position: absolute;
  z-index: 10;
  @media (min-width: ${Config.UI.large_screen}px) {
    bottom: 40px;
    left: 40px;
  }
  @media (max-width: ${Config.UI.large_screen}px) {
    bottom: 111px;
    left: 20px;
  }
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export { InfoBoxes }
