import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../../layer/ILoaderProps';
import { LoadableSource } from '../../../layer/LoadableSource';
import { Config } from '../../../../Config';

const AquaCultureDevelopmentZones = (props: ILoaderProps) => 
  <LoadableSource path="https://apigeostore.longline.co.uk/dataset/e5148b6492b04aa7bda6627c7d5b552d7f4262ad" {...props}>
    <Layer
      id='aquaculture_development_zones_inshore'
      beforeId={Config.UI.before_layer}
      type='fill'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      filter={['==', ['get', 'type'], 'Inshore aquaculture']}
      paint={{ 
        'fill-color': [
          'case',
          ['==', ['get', 'suitability'], 1], Config.suitability[1].color,
          ['==', ['get', 'suitability'], 2], Config.suitability[2].color,
          ['==', ['get', 'suitability'], 3], Config.suitability[3].color,
          ['==', ['get', 'suitability'], 4], Config.suitability[4].color,
          Config.suitability[0].color,
        ],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.8,
          0.5
        ]
      }}
    />
    <Layer
      id='aquaculture_development_zones_offshore'
      beforeId={Config.UI.before_layer}
      type='fill'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      filter={['==', ['get', 'type'], 'Offshore aquaculture']}
      paint={{ 
        'fill-pattern': 
          [
            'step',
            ['get', 'suitability'],
                'hatch-1',
            2, 'hatch-2',
            3, 'hatch-3',
            4, 'hatch-4'
          ],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.8,
          0.5
        ]
      }}
    />   
    <Layer
      id='aquaculture_development_zones_constraints'
      beforeId={Config.UI.before_layer}
      type='fill'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      filter={['==', ['get', 'type'], 'Spatial constraints']}
      paint={{ 
        'fill-color': Config.suitability[0].color,
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.8,
          0.5
        ]
      }}
    />        
    <Layer
      beforeId={Config.UI.before_layer}
      type='line'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{
        "line-color": [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          'gold',
          'black'
          ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          3,
          1
          ]
      }}
    />
  </LoadableSource>

export { AquaCultureDevelopmentZones } 
