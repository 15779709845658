import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../../layer/ILoaderProps';
import { LoadableSource } from '../../../layer/LoadableSource';
import { Config } from '../../../../Config';

const AdminDistricts = (props: ILoaderProps) => 
  <LoadableSource path="https://apigeostore.longline.co.uk/dataset/a9158407933470bfe8fcb66bdab0301962aab78d" ignore={['ADM0_EN', 'ADM1_EN']} {...props}>
    <Layer
      id='admin_districts'
      beforeId={Config.UI.before_layer}
      type='fill'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{ 
        "fill-color": "white",
        "fill-opacity": [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.2,
          0.1
          ]
      }}
    />
    <Layer
      id='admin_districts_stroke'
      beforeId={Config.UI.before_layer}
      type='line'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{ 
        "line-color": "white",
        "line-width": 2,
        "line-dasharray": [ 5, 2, 2, 2 ]
      }}
    />
    {/* <Layer 
      id='admin_districts_symbol'
      beforeId={Config.UI.before_layer}
      type='symbol'      
      layout={{ 
        'visibility': props.active ? "visible" : "none",
        'text-field': ['get', 'ADM2_EN'],
        'text-size': 20,
        'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular']
      }}
      paint={{
        'text-color': '#000',
        'text-halo-color': 'rgba(255,255,255,1)',
        'text-halo-width': 1,
        'text-halo-blur': 1
      }}
    /> */}  
  </LoadableSource>

export { AdminDistricts } 
