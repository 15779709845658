import * as React from  'react';
import styled from 'styled-components';

interface IItem {
  name: React.ReactNode;
  unit: React.ReactNode;
  value: React.ReactNode;
}

interface IProps {
  /** @ignore */
  className?: string;
  items: IItem[];
}

const FlexTableBase = (props: IProps) => {
  return (
    <div className={props.className}>
      {props.items.map((item, idx) => <Row key={idx}>
        <Left>
          {item.name}
          {item.unit && <Unit>
            &nbsp;({item.unit})
          </Unit>}
        </Left>
        <Right>
          {item.value}
        </Right>
      </Row>)}
    </div>
  )
}

const Left = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  min-width: 74px;
`

const Right = styled.div`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;  
  text-overflow: ellipsis;
`

const Unit = styled.div`
  display: inline;
  font-size: 80%;
  font-weight: normal;
  color: #ccc;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const FlexTable = styled(FlexTableBase)`
  color: white;
  width: 100%;
`
  
export { FlexTable }

