import * as React from 'react';
import styled from 'styled-components';
import { IDataset } from '../types/IDataset';
import { ActiveMap } from './ActiveMap';
import { LockPanel } from './LockPanel';
import { Config } from '../Config';

interface IProps {
  /** @ignore */
  className?: string;
  /** Datasets to pass to map. */
  datasets: IDataset[];
  /** Is the menu currently open? */
  open: boolean;
  /** Fired when a dataset must be deactivated. */
  onDeactivate: (datasetKey: string) => void;  
  fullscreenContainer: HTMLDivElement;
}

const MapHolderBase = (props: IProps) => {
  const [locked, setLocked] = React.useState(true);

  return (
    <div className={props.className}>
      {Config.map.password && locked && <LockPanel unlock={() => setLocked(false)}/>}
      <ActiveMap fullscreenContainer={props.fullscreenContainer} open={props.open} datasets={props.datasets} onDeactivate={props.onDeactivate}/>
    </div>
  )
}

const MapHolder = styled(MapHolderBase)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: black;
`

export { MapHolder }
