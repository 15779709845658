import * as React from 'react';
import styled from 'styled-components';
import { Config } from '../Config';

interface IProps {
  className?: string;
  unlock: () => void;
}

const LockPanelBase = (props: IProps) => {
  const [password, setPassword] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const handleSubmit = () => {
    if(password == Config.map.password) {
      props.unlock();
    }
  }

  return (
    <div className={props.className}>
      <Dialog>
        <DialogHeader>Enter password:</DialogHeader>
        <DialogForm>
          <input type='password' value={password} onChange={handleChange}/>
          <button onClick={handleSubmit}>Submit</button>
        </DialogForm>
      </Dialog>
    </div>
  );
}

const Dialog = styled.div`
  background: white;
  border-radius: 4px;
  padding: 20px;
`

const DialogHeader = styled.div`
  margin-bottom: 8px;
  text-align: center;
`

const DialogForm = styled.div`
  text-align: center;
`

const LockPanel = styled(LockPanelBase)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);

  button {
    margin-left: 8px;
    padding: 0 8px 0 8px;
  }
`

export { LockPanel }
