import * as React from 'react';
import styled from 'styled-components';

interface IProps { 
  className?: string;
  color: string;
  start: number;
  end: number;
}

const PieSliceBase = (props: IProps) => {
  return (
    <div className={props.className}>
    </div>
  )
}

const PieSlice = styled(PieSliceBase)`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: solid 2px #666;
  background: conic-gradient(transparent 0% ${p => p.start}%, ${p => p.color} ${p => p.start}% ${p => p.end}%, transparent ${p => p.end}%);
`

export { PieSlice }
