import * as React from 'react';

import { GuideBox } from '../elements/GuideBox';
import { GuideIcon } from '../elements/GuideIcon';
import { GuideButton } from '../elements/GuideButton';

interface IProps {
  onCancel: () => void;
  onStage: (s: string) => void;
}

const ZoomGuide = (props: IProps) => {
  return (
    <GuideBox top="32px" right="60px" onCancel={props.onCancel}>
      <h1>Zooming</h1>
      <p>Use the <GuideIcon icon="zoomin"/> and <GuideIcon icon="zoomout"/> buttons to zoom in or out.</p>
      <p>You can also double-click the map to zoom in.</p>
      <GuideButton onClick={() => props.onStage('tilting')}>Continue</GuideButton>
    </GuideBox>
  );
}

export { ZoomGuide }
