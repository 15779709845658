import * as React from 'react';
import { createRoot } from 'react-dom/client';
import styled from 'styled-components';

import { IBrick } from './IBrick';
import { Brick } from './Brick';

interface IProps {
  /** @ignore */
  className?: string;
  /** Bricks to show in masonry */
  bricks: IBrick[];
}

const MasonryBase = (props: IProps) => {
  return (
    <div className={props.className}>
      {props.bricks.map((b: IBrick) =>
        <Brick data={b} key={b.image}/>
      )}
    </div>
  );
}

const Masonry = styled(MasonryBase)`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(${p => Math.ceil(p.bricks.length / 3)}, 18rem);
  gap: 32px;
  @media (max-width: 940px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(${p => p.bricks.length}, 40vh);
  }
`

const node = document.getElementById('masonry') as Element;
if(node) {
  // Retrieve brick information from <span> children.
  const bricks: IBrick[] = Array.from(node.children).map(span => {
    return {
      title: span.getAttribute('data-title'),
      image: span.getAttribute('data-image'),
      onshore: {
        none: span.getAttribute('data-onshore-none') as any,
        low: span.getAttribute('data-onshore-low') as any,
        medium: span.getAttribute('data-onshore-medium') as any,
        high: span.getAttribute('data-onshore-high') as any,
      },
      offshore: {
        low: span.getAttribute('data-offshore-low') as any,
        medium: span.getAttribute('data-offshore-medium') as any,
        high: span.getAttribute('data-offshore-high') as any,
      }      
    };
  });
  const root = createRoot(node);
  root.render(<Masonry bricks={bricks}/>);
}
