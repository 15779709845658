import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../../layer/ILoaderProps';
import { LoadableSource } from '../../../layer/LoadableSource';
import { Config } from '../../../../Config';

const Extraction = (props: ILoaderProps) => 
  <LoadableSource path="https://apigeostore.longline.co.uk/dataset/7cd9dad678b7ec02aa39f2bec7c8099ef53675c9" {...props}>
    <Layer
      id='extraction'
      beforeId={Config.UI.before_layer}
      type='fill'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{ 
        'fill-pattern': 'hatch-2',
        'fill-opacity': 0.8
      }}
    />
    <Layer
      beforeId={Config.UI.before_layer}
      type='line'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{
        "line-dasharray": [ 5, 2, 2, 2 ],
        "line-color": [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          'gold',
          '#CC6B14'
          ],
        'line-width': 1
      }}      
    />
  </LoadableSource>

export { Extraction } 
