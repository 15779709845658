import * as React from 'react';

import Map, { AttributionControl, MapLayerMouseEvent, MapboxGeoJSONFeature, MapboxMap, Marker, ViewState, ViewStateChangeEvent } from 'react-map-gl';

import { AnimatedLoader } from "@independent-software/mapbox-ext/controls/AnimatedLoader";
import { ScaleControl } from "@independent-software/mapbox-ext/controls/ScaleControl";
import { ZoomInButton } from "@independent-software/mapbox-ext/controls/buttons/ZoomInButton";
import { ZoomOutButton } from "@independent-software/mapbox-ext/controls/buttons/ZoomOutButton";
import { CompassButton } from "@independent-software/mapbox-ext/controls/buttons/CompassButton";
import { FullscreenButton } from "@independent-software/mapbox-ext/controls/buttons/FullscreenButton";
import { LegendBox } from "@independent-software/mapbox-ext/controls/Legend/LegendBox";

import { LayerButton } from './components/LayerButton';
//import { InfoBoxes } from './InfoBoxes';
import { SawaSkin } from './mapcontrols/SawaSkin';
import styled from 'styled-components';
import { IDataset } from '../types/IDataset';
import { DebugWindow } from './mapcontrols/DebugWindow';
import { Legend } from './mapcontrols/Legend';
import { Config } from '../Config';
import { InfoBoxes } from './components/infobox/InfoBoxes';
import { Guide } from './guide/Guide';

interface IProps {
  datasets: IDataset[];
  /** Is menu currently open? */
  open: boolean;
  /** Fired when a dataset must be deactivated. */
  onDeactivate: (datasetKey: string) => void;

  fullscreenContainer: HTMLDivElement;
}

const ActiveMap = (props: IProps) => {
  const map = React.useRef<MapboxMap>(null);
  const [viewState, setViewState] = React.useState<ViewState>({
    longitude: Config.map.longitude, 
    latitude: Config.map.latitude, 
    zoom: Config.map.zoom,
    bearing: Config.map.bearing,
    pitch: Config.map.pitch,
    padding: { top: 0, bottom: 0, right: 0, left: 0 }
  });
  const [mouse, setMouse] = React.useState<{ lng: number, lat: number}>({ lng: 0, lat: 0 });
  const [satellite, setSatellite] = React.useState(true);
  const loadCount = React.useRef(0);
  const [loading, setLoading] = React.useState(false);
  // Features currently hovered:
  const [hovered, setHovered] = React.useState<MapboxGeoJSONFeature[]>([]);
  const [interactiveLayerIds, setInteractiveLayerIds] = React.useState<string[]>([]);
  const [marker, setMarker] = React.useState<{ lng: number, lat: number}>(null);
  const [markerFeatures, setMarkerFeatures] = React.useState<MapboxGeoJSONFeature[]>([]);

  const addLoader = () => {  
    loadCount.current++;
    setLoading(true);
  }

  const removeLoader = () => {
    loadCount.current--;
    setLoading(loadCount.current > 0);
  }  

  const handleToggleSatellite = () => setSatellite(!satellite);

  const handleLoad = (e: mapboxgl.MapboxEvent) => {
    map.current = e.target;
    // Find all layer IDs in the datasets, and concatenate them all together.
    // These will be the interactive layers.
    setInteractiveLayerIds([].concat.apply([], props.datasets.map(d => d.ids)));
  }

  const handleMove = (e: ViewStateChangeEvent) => setViewState(e.viewState);

  const handleMouseMove = (e: MapLayerMouseEvent) => {
    setMouse({ lng: e.lngLat.lng, lat: e.lngLat.lat });
    if(!map.current) return;

    // Clear previously-hovered features:
    hovered.forEach(f => {
      map.current.setFeatureState(f, { hover: false });
    });

    setHovered([]);
    if(!e.features) return;

    // Store currently-hovered features:
    setHovered(e.features);
    e.features.forEach(f => {
      map.current.setFeatureState(f, { hover: true });
    });
  }

  const handleClick = (e: MapLayerMouseEvent) => {
    // Ignore click events when map is open.
    if(props.open) return;

    // TODO: Place/remove marker.
    if(marker != null) {
      setMarker(null);
    } else {
      setMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      setMarkerFeatures(hovered);
    }
  }

  return (
    <Map
      {...viewState}
      mapboxAccessToken={Config.map.mapKey}
      style={{width: '100%', height: '100%'}}
      logoPosition="bottom-right"
      attributionControl={false}
      scrollZoom={true}
      interactiveLayerIds={interactiveLayerIds}
      mapStyle={satellite ? Config.map.satelliteStyle : Config.map.greyStyle}
      maxBounds={Config.map.max_bounds ?? null}
      minZoom={Config.map.minZoom}
      maxZoom={Config.map.maxZoom}
      cursor={hovered.length > 0 ? 'pointer' : 'auto'}
      onLoad={handleLoad}
      onMove={handleMove}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      <LargeScreenControls>
        <AttributionControl position='bottom-left'/>
        <ScaleControl {...viewState} x={props.open ? -420 : -60} y={-40} width={200}/>
        {/* Show style switch only if a secondary style is available: */}
        {Config.map.greyStyle && <LayerButton skin={SawaSkin} active={satellite} x={props.open ? -578 : -218} y={-260} onClick={handleToggleSatellite}/>}
        <FullscreenButton container={props.fullscreenContainer} skin={SawaSkin} x={props.open ? -578 : -218} y={-215}/>
        <ZoomInButton  skin={SawaSkin} {...viewState} x={props.open ? -578 : -218} y={-170}/>
        <ZoomOutButton skin={SawaSkin} {...viewState} x={props.open ? -578 : -218} y={-125}/>
        <CompassButton skin={SawaSkin} {...viewState} x={props.open ? -578 : -218} y={-80} visualizePitch/>
        <Legend x={props.open ? -420 : -60} y={-195} skin={SawaSkin} caption={Config.legend_caption}>
          {Config.suitability.filter(s => !s.hidden).map(s => <LegendBox key={s.name} color={s.color} label={s.name}/>)}
        </Legend>
        <DebugWindow skin={SawaSkin} x={props.open ? -420 : -60} y={-80} longitude={marker === null ? mouse.lng : marker.lng} latitude={marker === null ? mouse.lat : marker.lat}/>
        <AnimatedLoader x={props.open ? -600 : -230} y={-80} active={loading}/>
        <Guide/>
        <InfoBoxes 
          datasets={props.datasets.filter(d => d.active)} 
          features={marker === null ? hovered : markerFeatures} 
          skin={SawaSkin}
          onDeactivate={props.onDeactivate}
        />        
      </LargeScreenControls>

      <SmallScreenControls>
        <LayerButton   skin={SawaSkin} active={satellite} x={20} y={-70} onClick={handleToggleSatellite}/>
        <ZoomInButton  skin={SawaSkin} {...viewState} x={ 60} y={-70}/>
        <ZoomOutButton skin={SawaSkin} {...viewState} x={100} y={-70}/>
        <CompassButton skin={SawaSkin} {...viewState} x={140} y={-70} visualizePitch/>        
        <AnimatedLoader x={180} y={-70} active={loading}/>
      </SmallScreenControls>

      {props.datasets.map(dataset => 
        dataset.renderer({ key: dataset.key, active: dataset.active})
      )}

      {marker != null && <Marker latitude={marker.lat} longitude={marker.lng}/>}
    </Map>
  );
}

const LargeScreenControls = styled.div`
  @media not (min-width: ${Config.UI.large_screen}px) {
    display: none;
  }
  & > div {
    transition: right ease-in-out 100ms;
  }
`

const SmallScreenControls = styled.div`
  @media (min-width: ${Config.UI.large_screen}px) {
    display: none;
  }
`


export { ActiveMap }
