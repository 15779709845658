import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../../layer/ILoaderProps';
import { LoadableSource } from '../../../layer/LoadableSource';

const LandingSites = (props: ILoaderProps) => 
  <LoadableSource path="https://apigeostore.longline.co.uk/dataset/dff70659f6f2eee0eda382d9a5823c7e2c16ae77" {...props}>
    <Layer
      id='landing_sites_hover'
      type='circle'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{ 
        'circle-opacity': [ 'case', ['boolean', ['feature-state', 'hover'], false], 1, 0 ],        
        'circle-color': 'white',
        'circle-radius': 12.5,
        'circle-blur': 0
      }}
    />
    <Layer
      id='landing_sites'
      type='symbol'
      layout={{ 
        visibility: props.active ? "visible" : "none",
        'icon-image': 'lle_landing_site-21',
        'icon-size': 1,
        'icon-allow-overlap': true      
      }}
    />    
  </LoadableSource>

export { LandingSites } 

