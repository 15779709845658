import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../../layer/ILoaderProps';
import { LoadableSource } from '../../../layer/LoadableSource';
import { Config } from '../../../../Config';

const WaterSupply = (props: ILoaderProps) => 
  <LoadableSource path="https://apigeostore.longline.co.uk/dataset/184ea00dce6cb9bfa96f1b6279ea9d284178b6d1" {...props}>
    <Layer
      id='water_supply'
      type='circle'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{ 
        'circle-opacity': [ 'case', ['boolean', ['feature-state', 'hover'], false], 1, 0 ],        
        'circle-color': 'white',
        'circle-radius': 12.5,
        'circle-blur': 0
      }}
    />    
    <Layer
      id='water_supply_symbol'
      type='symbol'
      layout={{ 
        visibility: props.active ? "visible" : "none",
        'icon-image': 'lle_water-21',
        'icon-size': 1,
        'icon-allow-overlap': true      
      }}
    />    
  </LoadableSource>

export { WaterSupply } 

