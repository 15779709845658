import * as React from 'react';
import styled from 'styled-components';

import { MenuItem } from './MenuItem';
import { IDatagroup } from '../../../types/IDatagroup';
import { IDataset } from '../../../types/IDataset';

interface IProps { 
  /** @ignore */
  className?: string;
  /** Group to show. */
  group: IDatagroup;
  /** Datasets in the group. */
  datasets: IDataset[];
  /** Fired when a Dataset in the group is clicked. */
  onClick: (datasetKey: string) => void;
}

const MenuGroupBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Header>
        <Title>{props.group.title}</Title>
        <Description>{props.group.description}</Description>
      </Header>
      <Content>
        {props.datasets.sort((a,b) => a.title > b.title ? 1 : -1).map(dataset =>
          <MenuItem key={dataset.key} data={dataset} onClick={() => props.onClick(dataset.key)}/>)}
      </Content>
    </div>
  )
}

const Title = styled.div`
  color: #32DFC0;
  text-transform: uppercase;
  font-weight: 500;  
  line-height: 1em;
`

const Description = styled.div`
  color: #ddd;
  font-size: 12px;
`

const Header = styled.div`
  height: 68px;
  box-sizing: border-box;
  padding: 16px 16px 0 16px;
`

const Content = styled.div`
  
`

const MenuGroup = styled(MenuGroupBase)`
`

export { MenuGroup }
