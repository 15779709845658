import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../../layer/ILoaderProps';
import { LoadableSource } from '../../../layer/LoadableSource';
import { Config } from '../../../../Config';

const DASHES = [
  [0, 4, 3],
  [1, 4, 2],
  [2, 4, 1],
  [3, 4, 0],
  [0, 1, 3, 3],
  [0, 2, 3, 2],
  [0, 3, 3, 1]
]

const NavigationRoutes = (props: ILoaderProps) => {
  const realOffset = React.useRef(0);
  const [dashOffset, setDashOffset] = React.useState(0);
  const intervalID = React.useRef(null);

  const updateDashArray = () => {
    // A ref is used here to keep the "real" dash offset, because this
    // method is called by an event handler and not by react. It will
    // not have access to the current state, but only the initial state.
    // Therefore dashOffset will always by 0.
    realOffset.current = (realOffset.current +1) % DASHES.length;
    setDashOffset(realOffset.current);
  }

  React.useEffect(() => {
    intervalID.current = window.setInterval(updateDashArray, 100);
    return () => {
      window.clearInterval(intervalID.current);
    }
  }, [props.active]);

  return (
    <LoadableSource path="https://apigeostore.longline.co.uk/dataset/95b8ae9ae8c52bb103cf23193e51f093f0a7e357" {...props}>
      <Layer
        id='navigation_routes'
        beforeId={Config.UI.before_layer}
        type="line"
        layout={{ visibility: props.active ? "visible" : "none" }}          
        paint={{ 
          "line-color": "orange",
          "line-width": [
            'interpolate',
            ['linear'],
            ["zoom"],
            9, 2,
            15, 10
          ],
          "line-dasharray": DASHES[dashOffset]
        }}
      />
    </LoadableSource>
  );
}

export { NavigationRoutes }
