import * as React from 'react';
import { createRoot } from 'react-dom/client';
import styled from 'styled-components';

import { Slide } from './Slide';
import { SlideIndexButton } from './SlideIndexButton';
import { SlidePauseButton } from './SlidePauseButton';
import { ISlide } from './ISlide';

//
// Sample of use
//
// <div id="slideshow" data-height="80vh" data-delay="5000">
//   <span data-image="road.jpg" data-title="Food security" data-text="Fostering aquaculture for food security and nutritious sources of protein for Pakistan is a key priority."></span>
//   <span data-image="feed.jpg" data-title="Sustainable aquaculture" data-text="Developing aquaculture through proper spatial planning plays a vital role in increasing foods demands and creating opportunities for economic development."></span>
//   <span data-image="clean.jpg" data-title="Fostering growth" data-text="Developing aquaculture in the best growing locations, maximises the potential for economic growth in rural areas, supports livelihoods and reduces poverty."></span>
//   <span data-image="net.jpg" data-title="Environmental sustainability" data-text="Spatial planning reduces risk for the aquaculture private sector and optimising competitiveness, water resources, and ecosystem health."></span>
//   <span data-image="farms.jpg" data-title="Technology and innovation" data-text="The aquaculture atlas provides robust analytics that strengthen governance decisions for aquaculture."></span>
// </div>

// Duration that a slide stays visible before moving to the next slide.
const DEFAULT_SLIDE_DELAY  = 5000; // ms
// Duration for sliding animation.
const TRANSITION_TIME = 500;       // ms

interface IProps {
  /** @ignore */
  className?: string;
  /**
   * Slideshow slides.
   */
  slides: ISlide[];
  /** 
   * Slideshow height, as CSS expression, e.g. "50vh".
   * Defaults to 50vh.
   */
  height?: string
  /**
   * Slideshow slide delay (ms). Defaults to 500.
   */
  delay?: number;
}

const SlideshowBase = (props: IProps) => {
  const interval = React.useRef<number>(null);
  const [active, setActive] = React.useState(true);
  const [prev, setPrev] = React.useState(0);
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    interval.current = window.setInterval(() => {
      setPrev(index);
      setIndex((index + 1) % props.slides.length);
    }, props.delay ?? DEFAULT_SLIDE_DELAY);
    return () => clearInterval(interval.current);
  }, [index]);

  const handleClickIndex = (idx: number) => {
    setPrev(index);
    setIndex(idx);
  }

  const handleToggleActive = () => {
    setActive(!active);
    // Stop slideshow.
    if(active == true) {
      if(interval.current != null) {
        clearInterval(interval.current);
        interval.current = null;
      }
    } 
    // Start slideshow, immediately going to the next slide.
    else {
      setPrev(index);
      setIndex((index + 1) % props.slides.length);      
    }
  }

  return (
    <div className={props.className}>
      <Strip>
        {props.slides.map((slide, idx) => 
          <Slide 
            key={idx} 
            state={index == idx ? 'onstage' : (prev == idx ? 'offstage' : 'ready')} 
            data={slide} 
            transitionTime={TRANSITION_TIME}
            count={props.slides.length}
            index={idx}
          />
        )}
      </Strip>
      {/* One index button for each slide. */}
      <IndexBlock>
        {Array.from(Array(props.slides.length).keys()).map(idx => 
          <SlideIndexButton key={idx} active={idx === index} onClick={() => handleClickIndex(idx)}/>
        )}
        <SlidePauseButton active={active} onClick={handleToggleActive}/>
      </IndexBlock>      
    </div>
  );
}

const Strip = styled.div`
  position: relative;
  z-index: 0;
  overflow: hidden;
`

const IndexBlock = styled.div`
  position: absolute;
  left: 40px;
  bottom: 20px;
  background: black;
  opacity: 0.7;
  display: flex;
  align-items: center;
  padding: 5px 15px 5px 15px;
  z-index: 15;
`

const Slideshow = styled(SlideshowBase)`
  position: relative;
  margin-bottom: 60px;
  overflow-x: hidden;
  ${Strip} {
    height: ${p => p.height ?? '50vh'};
  }
`

const node = document.getElementById('slideshow') as Element;
if(node) {
  // Retrieve slide information from <span> children.
  const slides: ISlide[] = Array.from(node.children).map(span => {
    return {
      image: span.getAttribute('data-image'),
      title: span.getAttribute('data-title'),
      text: span.getAttribute('data-text')
    };
  });
  // Retrieve slideshow attributes from node.
  const height = node.getAttribute('data-height');
  const delay = node.getAttribute('data-delay');
  const root = createRoot(node);
  root.render(<Slideshow height={height} delay={delay as any} slides={slides}/>);
}
