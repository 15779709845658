import * as React from 'react';
import axios from 'axios';
import { Source } from 'react-map-gl';

import { ILoaderProps } from './ILoaderProps';
import { Config } from '../../Config';

interface IProps {
  /** @ignore */
  children?: React.ReactNode;
  /** GeoJSON file name. */
  path: string;
  /** Add clustering? */
  cluster?: boolean;
  /** Is layer currently active? */
  active: boolean;
  /** List of properties to ignore. */  
  ignore?: string[]
}

/**
 * Loads a geojson resource (path) from the server. onLoading is fired when
 * loading begins; onLoaded is fired when loading is complete. Renders a 
 * Source which may contain Layer children.
 */
const LoadableSource = (props: IProps & ILoaderProps) => {
  const [data, setData] = React.useState<GeoJSON.FeatureCollection>(null);

  React.useEffect(() => { if(props.active) loadData(); }, [props.active]);

  const loadData = () => {
    // props.onLoading();
    window.setTimeout(() => {
      axios.get(props.path)
      .then(res => {
        let geojson = res.data as GeoJSON.FeatureCollection;

        // Filter GeoJSON for ignored properties:
        if(props.ignore) {
          geojson.features.forEach(f => {
            f.properties = Object.keys(f.properties)
              .filter(key => !props.ignore.includes(key))
              .reduce((obj, key) => {
                (obj as any)[key] = f.properties[key];
                return obj;
              }, {});
          });
        }

        setData(geojson);
        /* 
        const json = JSON.parse(Decoder.decode(res.data));
        // Add missing "type" element to each feature:
        json.forEach((f:any) => f["type"] = "Feature");
        // Contain json data in a GeoJSON block:
        const geojson: GeoJSON.FeatureCollection = {
          "type": "FeatureCollection",
          "features": json
        };
        // For each feature, lengthen keys:
        geojson.features.forEach((f:any) => {
          f["properties"] = f["p"];
          delete f["p"];
          f["geometry"] = f["g"];
          delete f["g"];
          f["geometry"]["coordinates"] = f["geometry"]["c"];
          delete f["geometry"]["c"];
        });

        // Filter GeoJSON for ignored properties:
        if(props.ignore) {
          geojson.features.forEach(f => {
            f.properties = Object.keys(f.properties)
              .filter(key => !props.ignore.includes(key))
              .reduce((obj, key) => {
                (obj as any)[key] = f.properties[key];
                return obj;
              }, {});
          });
        }
        // p.onLoaded();
        setData(geojson); */
      });
    }, Math.random() * Config.UI.load_delay_random + Config.UI.load_delay_base);
  }

  return (
    // generateId creates an ID for each feature, since the source data
    // does not have any IDs. This is required for hovering.
    <Source 
      key={props.key} 
      id={props.path} 
      cluster={props.cluster ? true : false} 
      clusterRadius={75} 
      generateId 
      type="geojson" 
      data={data}
    >
      {props.children}
    </Source>
  );
}

export { LoadableSource }
