import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  className?: string;
  icon: string;
}

const GuideIconBase = (props: IProps) => {
  return (
    <svg className={props.className}>
      <use xlinkHref={`sprites.svg#${props.icon}`}></use>
    </svg>
  );
}

const GuideIcon = styled(GuideIconBase)`
  width: 10px;
  height: 10px;
  fill: #fff;
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
`

export { GuideIcon }

